import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/config-ui";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiSetup = "/v1/setup";

function* getConfigUIHome(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(`${apiSetup}/HOME_WEB_CONFIG`));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_UI_HOME_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_UI_HOME_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CONFIG_UI_HOME_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetConfigUIHome() {
  yield takeLatest(ACTION_TYPE.GET_CONFIG_UI_HOME_REQUEST, getConfigUIHome);
}

function* getConfigUIMenu(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(`${apiSetup}/MENU_WEB_CONFIG`));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_UI_MENU_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_UI_MENU_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CONFIG_UI_MENU_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetConfigUIMenu() {
  yield takeLatest(ACTION_TYPE.GET_CONFIG_UI_MENU_REQUEST, getConfigUIMenu);
}

function* updateConfigUIHome(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiSetup}/HOME_WEB_CONFIG`, data)
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.UPDATE_CONFIG_UI_HOME_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.UPDATE_CONFIG_UI_HOME_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.UPDATE_CONFIG_UI_HOME_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchUpdateConfigUIHome() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.UPDATE_CONFIG_UI_HOME_REQUEST,
    updateConfigUIHome
  );
}

function* updateConfigUIMenu(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiSetup}/MENU_WEB_CONFIG`, data)
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.UPDATE_CONFIG_UI_MENU_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.UPDATE_CONFIG_UI_MENU_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.UPDATE_CONFIG_UI_MENU_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchUpdateConfigUIMenu() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.UPDATE_CONFIG_UI_MENU_REQUEST,
    updateConfigUIMenu
  );
}

function* getConfigKey(action) {
  const { key, success, error } = action;
  try {
    const response = yield call(() => axios.get(`${apiSetup}/${key}`));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_KEY_SUCCESS,
        payload: response?.data,
      });
      success && success(response);
    } else {
      yield put({
        type: ACTION_TYPE.GET_CONFIG_KEY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CONFIG_KEY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetConfigKey() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.GET_CONFIG_KEY_REQUEST,
    getConfigKey
  );
}

function* editConfigKey(action) {
  const { data, key, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiSetup}/${key}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_CONFIG_KEY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_CONFIG_KEY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_CONFIG_KEY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchEditConfigKey() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_CONFIG_KEY_REQUEST,
    editConfigKey
  );
}

const saga = [
  watchGetConfigUIHome(),
  watchGetConfigUIMenu(),
  watchUpdateConfigUIHome(),
  watchUpdateConfigUIMenu(),
  watchGetConfigKey(),
  watchEditConfigKey(),
];

export default saga;
