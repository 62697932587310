export const ACTION_TYPE = {
  GET_TRANSACTION_LIST_REQUEST: "transaction/GET_TRANSACTION_LIST_REQUEST",
  GET_TRANSACTION_LIST_SUCCESS: "transaction/GET_TRANSACTION_LIST_SUCCESS",
  GET_TRANSACTION_LIST_ERROR: "transaction/GET_TRANSACTION_LIST_ERROR",

  DELETE_TRANSACTION_REQUEST: "transaction/DELETE_TRANSACTION_REQUEST",
  DELETE_TRANSACTION_SUCCESS: "transaction/DELETE_TRANSACTION_SUCCESS",
  DELETE_TRANSACTION_ERROR: "transaction/DELETE_TRANSACTION_ERROR",
};

const initialState = {
  transactionList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
