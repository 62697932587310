import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/keyword";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiKeyword = "/v1/tag";

function* getKeywordList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiKeyword, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_KEYWORD_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_KEYWORD_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_KEYWORD_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetKeywordList() {
  yield takeLatest(ACTION_TYPE.GET_KEYWORD_LIST_REQUEST, getKeywordList);
}

function* addKeyword(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiKeyword, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_KEYWORD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_KEYWORD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_KEYWORD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddKeyword() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_KEYWORD_REQUEST, addKeyword);
}

function* editKeyword(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiKeyword}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_KEYWORD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_KEYWORD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_KEYWORD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditKeyword() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_KEYWORD_REQUEST, editKeyword);
}

function* deleteKeyword(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.delete(apiKeyword, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_KEYWORD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_KEYWORD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_KEYWORD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteKeyword() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_KEYWORD_REQUEST,
    deleteKeyword
  );
}

const saga = [
  watchGetKeywordList(),
  watchAddKeyword(),
  watchEditKeyword(),
  watchDeleteKeyword(),
];

export default saga;
