import { combineReducers } from "redux";
import loading from "./loading";
import auth from "./auth";
import role from "./role";
import configui from "./config-ui";
import post from "./post";
import category from "./category";
import event from "./event";
import emailSub from "./email-sub";
import news from "./news";
import agency from "./agency";
import product from "./product";
import notification from "./notification";
import projectmap from "./project-map";
import keyword from "./keyword";
import mobileUser from "./mobile-user";
import contactForm from "./contact-form";
import feedback from "./feedback";
import transaction from "./transaction";
import productreview from "./product-review";
import dashboard from "./dashboard";
import admin from "./admin";

const rootReducer = combineReducers({
  auth,
  loading,
  role,
  configui,
  category,
  post,
  event,
  emailSub,
  news,
  agency,
  product,
  notification,
  projectmap,
  keyword,
  mobileUser,
  contactForm,
  feedback,
  transaction,
  productreview,
  dashboard,
  admin,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
