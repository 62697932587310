import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/category";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiCategory = "/v1/category";

function* getCategoryList(action) {
  const { success, error } = action;
  try {
    const response = yield call(() =>
      axios.get(apiCategory, { params: { limit: 500 } })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CATEGORY_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_CATEGORY_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CATEGORY_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetCategoryList() {
  yield takeLatest(ACTION_TYPE.GET_CATEGORY_LIST_REQUEST, getCategoryList);
}

function* addCategory(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiCategory, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_CATEGORY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_CATEGORY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_CATEGORY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddCategory() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_CATEGORY_REQUEST, addCategory);
}

function* editCategory(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiCategory}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_CATEGORY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_CATEGORY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_CATEGORY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditCategory() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_CATEGORY_REQUEST,
    editCategory
  );
}

function* deleteCategory(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiCategory, null, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_CATEGORY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_CATEGORY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_CATEGORY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteCategory() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_CATEGORY_REQUEST,
    deleteCategory
  );
}

const saga = [
  watchGetCategoryList(),
  watchAddCategory(),
  watchEditCategory(),
  watchDeleteCategory(),
];

export default saga;
