import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/project-map";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiCity = "/v1/city";
const apiDistrict = "/v1/district";
const apiWard = "/v1/ward";

function* getCityList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiCity, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CITY_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_CITY_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CITY_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetCityList() {
  yield takeLatest(ACTION_TYPE.GET_CITY_LIST_REQUEST, getCityList);
}

function* getDistrictList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiDistrict, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_DISTRICT_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_DISTRICT_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_DISTRICT_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetDistrictList() {
  yield takeLatest(ACTION_TYPE.GET_DISTRICT_LIST_REQUEST, getDistrictList);
}

function* getWardList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiWard, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_WARD_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_WARD_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_WARD_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetWardList() {
  yield takeLatest(ACTION_TYPE.GET_WARD_LIST_REQUEST, getWardList);
}

function* addCity(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiCity, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_CITY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_CITY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_CITY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddCity() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_CITY_REQUEST, addCity);
}

function* addDistrict(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiDistrict, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_DISTRICT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_DISTRICT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_DISTRICT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddDistrict() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_DISTRICT_REQUEST, addDistrict);
}

function* addWard(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiWard, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_WARD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_WARD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_WARD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddWard() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_WARD_REQUEST, addWard);
}

function* editCity(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiCity}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_CITY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_CITY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_CITY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditCity() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_CITY_REQUEST, editCity);
}

function* editDistrict(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiDistrict}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_DISTRICT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_DISTRICT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_DISTRICT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditDistrict() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_DISTRICT_REQUEST,
    editDistrict
  );
}

function* editWard(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiWard}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_WARD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_WARD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_WARD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditWard() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_WARD_REQUEST, editWard);
}

function* deleteCity(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiCity}`, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_CITY_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_CITY_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_CITY_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteCity() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_CITY_REQUEST, deleteCity);
}

function* deleteDistrict(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiDistrict}`, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_DISTRICT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_DISTRICT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_DISTRICT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteDistrict() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_DISTRICT_REQUEST,
    deleteDistrict
  );
}

function* deleteWard(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiWard}`, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_WARD_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_WARD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_WARD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteWard() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_WARD_REQUEST, deleteWard);
}

const saga = [
  watchGetCityList(),
  watchAddCity(),
  watchEditCity(),
  watchDeleteCity(),
  watchGetDistrictList(),
  watchAddDistrict(),
  watchEditDistrict(),
  watchDeleteDistrict(),
  watchGetWardList(),
  watchAddWard(),
  watchEditWard(),
  watchDeleteWard(),
];

export default saga;
