import { convertTranslations } from "../util/validate";

export const ACTION_TYPE = {
  GET_CATEGORY_LIST_REQUEST: "category/GET_CATEGORY_LIST_REQUEST",
  GET_CATEGORY_LIST_SUCCESS: "category/GET_CATEGORY_LIST_SUCCESS",
  GET_CATEGORY_LIST_ERROR: "category/GET_CATEGORY_LIST_ERROR",

  ADD_CATEGORY_REQUEST: "category/ADD_CATEGORY_REQUEST",
  ADD_CATEGORY_SUCCESS: "category/ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_ERROR: "category/ADD_CATEGORY_ERROR",
  EDIT_CATEGORY_REQUEST: "category/EDIT_CATEGORY_REQUEST",
  EDIT_CATEGORY_SUCCESS: "category/EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_ERROR: "category/EDIT_CATEGORY_ERROR",
  DELETE_CATEGORY_REQUEST: "category/DELETE_CATEGORY_REQUEST",
  DELETE_CATEGORY_SUCCESS: "category/DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "category/DELETE_CATEGORY_ERROR",
};

const initialState = {
  categoryList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_CATEGORY_LIST_SUCCESS:
      let categoryList = action?.payload?.results;
      if (Array.isArray(categoryList)) {
        categoryList.forEach((item) => convertTranslations(item));
      }
      return {
        ...state,
        categoryList: categoryList
      };
    default:
      return state;
  }
};

export default reducer;
