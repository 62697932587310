export const ACTION_TYPE = {
  GET_ADMIN_LIST_REQUEST: "admin/GET_ADMIN_LIST_REQUEST",
  GET_ADMIN_LIST_SUCCESS: "admin/GET_ADMIN_LIST_SUCCESS",
  GET_ADMIN_LIST_ERROR: "admin/GET_ADMIN_LIST_ERROR",

  ADD_ADMIN_REQUEST: "admin/ADD_ADMIN_REQUEST",
  ADD_ADMIN_SUCCESS: "admin/ADD_ADMIN_SUCCESS",
  ADD_ADMIN_ERROR: "admin/ADD_ADMIN_ERROR",
  EDIT_ADMIN_REQUEST: "admin/EDIT_ADMIN_REQUEST",
  EDIT_ADMIN_SUCCESS: "admin/EDIT_ADMIN_SUCCESS",
  EDIT_ADMIN_ERROR: "admin/EDIT_ADMIN_ERROR",
  DELETE_ADMIN_REQUEST: "admin/DELETE_ADMIN_REQUEST",
  DELETE_ADMIN_SUCCESS: "admin/DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_ERROR: "admin/DELETE_ADMIN_ERROR",
};

const initialState = {
  adminList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        adminList: action?.payload?.results,
      };
    default:
      return state;
  }
};

export default reducer;
