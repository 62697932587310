import { convertTranslations } from "../util/validate";

export const ACTION_TYPE = {
  GET_POST_LIST_REQUEST: "post/GET_POST_LIST_REQUEST",
  GET_POST_LIST_SUCCESS: "post/GET_POST_LIST_SUCCESS",
  GET_POST_LIST_ERROR: "post/GET_POST_LIST_ERROR",

  GET_POST_REQUEST: "post/GET_POST_REQUEST",
  GET_POST_SUCCESS: "post/GET_POST_SUCCESS",
  GET_POST_ERROR: "post/GET_POST_ERROR",

  ADD_POST_REQUEST: "post/ADD_POST_REQUEST",
  ADD_POST_SUCCESS: "post/ADD_POST_SUCCESS",
  ADD_POST_ERROR: "post/ADD_POST_ERROR",
  EDIT_POST_REQUEST: "post/EDIT_POST_REQUEST",
  EDIT_POST_SUCCESS: "post/EDIT_POST_SUCCESS",
  EDIT_POST_ERROR: "post/EDIT_POST_ERROR",
  DELETE_POST_REQUEST: "post/DELETE_POST_REQUEST",
  DELETE_POST_SUCCESS: "post/DELETE_POST_SUCCESS",
  DELETE_POST_ERROR: "post/DELETE_POST_ERROR",
};

const initialState = {
  postList: [],
  post: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_POST_LIST_SUCCESS:
      let postList = action?.payload?.results;
      if (Array.isArray(postList)) {
        postList.forEach((item) => convertTranslations(item));
      }
      return {
        ...state,
        postList,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
