import { all } from "redux-saga/effects";
import auth from "./auth";
import role from "./role";
import configui from "./config-ui";
import category from "./category";
import post from "./post";
import uploadFile from "./upload-file";
import event from "./event";
import emailSub from "./email-sub";
import news from "./news";
import agency from "./agency";
import product from "./product";
import notification from "./notification";
import projectmap from "./project-map";
import keyword from "./keyword";
import transaction from "./transaction";
import productreview from "./product-review";
import mobileUser from "./mobile-user";
import contactForm from "./contact-form";
import feedback from "./feedback";
import dashboard from "./dashboard";
import admin from "./admin";

export default function* rootSaga() {
  yield all([
    ...auth,
    ...role,
    ...category,
    ...post,
    ...uploadFile,
    ...event,
    ...configui,
    ...emailSub,
    ...news,
    ...agency,
    ...product,
    ...notification,
    ...projectmap,
    ...keyword,
    ...mobileUser,
    ...contactForm,
    ...feedback,
    ...transaction,
    ...productreview,
    ...dashboard,
    ...admin,
  ]);
}
