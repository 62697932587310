export const ACTION_TYPE = {
  GET_ALL_PERMISSION_REQUEST: "role/GET_ALL_PERMISSION_REQUEST",
  GET_ALL_PERMISSION_SUCCESS: "role/GET_ALL_PERMISSION_SUCCESS",
  GET_ALL_PERMISSION_ERROR: "role/GET_ALL_PERMISSION_ERROR",

  GET_PERMISSION_GROUP_LIST_REQUEST: "role/GET_PERMISSION_GROUP_LIST_REQUEST",
  GET_PERMISSION_GROUP_LIST_SUCCESS: "role/GET_PERMISSION_GROUP_LIST_SUCCESS",
  GET_PERMISSION_GROUP_LIST_ERROR: "role/GET_PERMISSION_GROUP_LIST_ERROR",

  ADD_PERMISSION_GROUP_REQUEST: "role/ADD_PERMISSION_GROUP_REQUEST",
  ADD_PERMISSION_GROUP_SUCCESS: "role/ADD_PERMISSION_GROUP_SUCCESS",
  ADD_PERMISSION_GROUP_ERROR: "role/ADD_PERMISSION_GROUP_ERROR",
  EDIT_PERMISSION_GROUP_REQUEST: "role/EDIT_PERMISSION_GROUP_REQUEST",
  EDIT_PERMISSION_GROUP_SUCCESS: "role/EDIT_PERMISSION_GROUP_SUCCESS",
  EDIT_PERMISSION_GROUP_ERROR: "role/EDIT_PERMISSION_GROUP_ERROR",
  DELETE_PERMISSION_GROUP_REQUEST: "role/DELETE_PERMISSION_GROUP_REQUEST",
  DELETE_PERMISSION_GROUP_SUCCESS: "role/DELETE_PERMISSION_GROUP_SUCCESS",
  DELETE_PERMISSION_GROUP_ERROR: "role/DELETE_PERMISSION_GROUP_ERROR",

  GET_FIND_SETUP_REQUEST: "config-ui/GET_FIND_SETUP_REQUEST",
  GET_FIND_SETUP_SUCCESS: "config-ui/GET_FIND_SETUP_SUCCESS",
  GET_FIND_SETUP_ERROR: "config-ui/GET_FIND_SETUP_ERROR",
};

const initialState = {
  allPermisstion: [],
  permissionGroupList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_ALL_PERMISSION_SUCCESS:
      return {
        ...state,
        allPermisstion: action?.payload,
      };
    case ACTION_TYPE.GET_PERMISSION_GROUP_LIST_SUCCESS:
      return {
        ...state,
        permissionGroupList: action?.payload?.results,
      };
    default:
      return state;
  }
};

export default reducer;
