export const ACTION_TYPE = {
  GET_EMAIL_LIST_REQUEST: "email-sub/GET_EMAIL_LIST_REQUEST",
  GET_EMAIL_LIST_SUCCESS: "email-sub/GET_EMAIL_LIST_SUCCESS",
  GET_EMAIL_LIST_ERROR: "email-sub/GET_EMAIL_LIST_ERROR",

  ADD_EMAIL_REQUEST: "email-sub/ADD_EMAIL_REQUEST",
  ADD_EMAIL_SUCCESS: "email-sub/ADD_EMAIL_SUCCESS",
  ADD_EMAIL_ERROR: "email-sub/ADD_EMAIL_ERROR",
  EDIT_EMAIL_REQUEST: "email-sub/EDIT_EMAIL_REQUEST",
  EDIT_EMAIL_SUCCESS: "email-sub/EDIT_EMAIL_SUCCESS",
  EDIT_EMAIL_ERROR: "email-sub/EDIT_EMAIL_ERROR",
  DELETE_EMAIL_REQUEST: "email-sub/DELETE_EMAIL_REQUEST",
  DELETE_EMAIL_SUCCESS: "email-sub/DELETE_EMAIL_SUCCESS",
  DELETE_EMAIL_ERROR: "email-sub/DELETE_EMAIL_ERROR",
};

const initialState = {
  emailList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        emailList: action?.payload?.results,
        hasNext: action?.payload?.hasNext
      };
    default:
      return state;
  }
};

export default reducer;
