import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/event";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiEvent = "/v1/event";

function* getEventList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiEvent, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_EVENT_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_EVENT_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_EVENT_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetEventList() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.GET_EVENT_LIST_REQUEST,
    getEventList
  );
}

function* addEvent(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiEvent, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_EVENT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_EVENT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_EVENT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddEvent() {
  yield takeLatest(ACTION_TYPE.ADD_EVENT_REQUEST, addEvent);
}

function* editEvent(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiEvent}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_EVENT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_EVENT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_EVENT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditEvent() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_EVENT_REQUEST, editEvent);
}

function* deleteEvent(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiEvent, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_EVENT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_EVENT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_EVENT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteEvent() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_EVENT_REQUEST, deleteEvent);
}

const saga = [
  watchGetEventList(),
  watchAddEvent(),
  watchEditEvent(),
  watchDeleteEvent(),
];

export default saga;
