import { call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/feedback";
import { validateStatus } from "../util/validate";

const apiFeedback = "/v1/user-feedback";

function* getFeedbackList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.get(apiFeedback, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_FEEDBACK_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_FEEDBACK_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_FEEDBACK_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetFeedbackList() {
  yield takeLatest(ACTION_TYPE.GET_FEEDBACK_LIST_REQUEST, getFeedbackList);
}

const saga = [
  watchGetFeedbackList(),
];

export default saga;
