import { IconButton, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import DarkButton from "../../../Components/button/dark-button";
import { RootState } from "../../../reducer";
import { removeToken } from "../../../reducer/auth";
import { ACTION_TYPE } from "../../../reducer/loading";

const ModalErrorMessage = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(
    (store: RootState) => store.loading.errorMessage
  );
  const onClose = () => {
    if (
      Array.isArray(errorMessage) &&
      errorMessage.find(
        (item) => item?.statusCode === 401 || item?.statusCode === 403
      )
    ) {
      removeToken();
      window.location.href = "/login";
    }
    dispatch({ type: ACTION_TYPE.SET_ERROR_MESSAGE, payload: [] });
  };

  useEffect(() => {
    if (errorMessage.length > 0) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [errorMessage]);

  return createPortal(
    <Modal open={errorMessage.length > 0}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Lỗi</h4>
          <IconButton
            aria-label="close"
            style={{ padding: 6 }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <div className="modal-body">
          {errorMessage.map((item, index) => {
            if (typeof item === "object" && item !== null) {
              return (
                <p className="modal-message" key={index}>
                  {item?.message}
                </p>
              );
            } else if (typeof item === "string") {
              return (
                <p className="modal-message" key={index}>
                  {item}
                </p>
              );
            }
            return null;
          })}
        </div>
        <div className="modal-footer">
          <DarkButton onClick={onClose}>Đóng</DarkButton>
        </div>
      </div>
    </Modal>,
    document.body
  );
};
export default ModalErrorMessage;
