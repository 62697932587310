export const ACTION_TYPE = {
  GET_PRODUCT_REVIEW_LIST_REQUEST:
    "product_review/GET_PRODUCT_REVIEW_LIST_REQUEST",
  GET_PRODUCT_REVIEW_LIST_SUCCESS:
    "product_review/GET_PRODUCT_REVIEW_LIST_SUCCESS",
  GET_PRODUCT_REVIEW_LIST_ERROR: "product_review/GET_PRODUCT_REVIEW_LIST_ERROR",
  EDIT_PRODUCT_REVIEW_LIST_STATUS_REQUEST:
    "product_review/EDIT_PRODUCT_REVIEW_LIST_STATUS_REQUEST",
  EDIT_PRODUCT_REVIEW_LIST_STATUS_SUCCESS:
    "product_review/EDIT_PRODUCT_REVIEW_LIST_STATUS_SUCCESS",
  EDIT_PRODUCT_REVIEW_LIST_STATUS_ERROR:
    "product_review/EDIT_PRODUCT_REVIEW_LIST_STATUS_ERROR",
};

const initialState = {
  productReviewList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_PRODUCT_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        productReviewList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
