import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/news";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiNews = "/v1/news";

function* getNewsList(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(apiNews));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_NEWS_LIST_SUCCESS,
        payload: response.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_NEWS_LIST_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_NEWS_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetNewsList() {
  yield takeLatest(ACTION_TYPE.GET_NEWS_LIST_REQUEST, getNewsList);
}

function* editNews(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiNews}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_NEWS_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_NEWS_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_NEWS_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditNews() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_NEWS_REQUEST, editNews);
}

const saga = [watchGetNewsList(), watchEditNews()];

export default saga;
