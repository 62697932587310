import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/role";
import { validateStatus } from "../util/validate";
import { SAGA_THROTTLE } from "../util/constant";
const apiPermission = "/v1/permission";
const apiPermissionGroup = "/v1/permission-group";

function* getAllPermission(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(apiPermission));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_ALL_PERMISSION_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_ALL_PERMISSION_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_ALL_PERMISSION_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetAllPermission() {
  yield takeLatest(ACTION_TYPE.GET_ALL_PERMISSION_REQUEST, getAllPermission);
}

function* getPermissionGroupList(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(apiPermissionGroup));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_PERMISSION_GROUP_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_PERMISSION_GROUP_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_PERMISSION_GROUP_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetPermissionGroupList() {
  yield takeLatest(
    ACTION_TYPE.GET_PERMISSION_GROUP_LIST_REQUEST,
    getPermissionGroupList
  );
}

function* addPermissionGroup(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiPermissionGroup, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_PERMISSION_GROUP_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_PERMISSION_GROUP_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_PERMISSION_GROUP_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddPermissionGroup() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.ADD_PERMISSION_GROUP_REQUEST,
    addPermissionGroup
  );
}

function* editPermissionGroup(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiPermissionGroup}/${id}`, data)
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_PERMISSION_GROUP_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_PERMISSION_GROUP_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_PERMISSION_GROUP_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchEditPermissionGroup() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_PERMISSION_GROUP_REQUEST,
    editPermissionGroup
  );
}

function* deletePermissionGroup(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiPermissionGroup, null, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_PERMISSION_GROUP_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_PERMISSION_GROUP_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_PERMISSION_GROUP_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeletePermissionGroup() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_PERMISSION_GROUP_REQUEST,
    deletePermissionGroup
  );
}

const saga = [
  watchGetAllPermission(),
  watchGetPermissionGroupList(),
  watchAddPermissionGroup(),
  watchEditPermissionGroup(),
  watchDeletePermissionGroup(),
];

export default saga;
