import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/admin";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiAdmin = "/v1/manager";

function* getAdminList(action) {
  const { success, error } = action;
  try {
    const response = yield call(() =>
      axios.get(apiAdmin, { params: { limit: 500 } })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_ADMIN_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_ADMIN_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_ADMIN_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetAdminList() {
  yield takeLatest(ACTION_TYPE.GET_ADMIN_LIST_REQUEST, getAdminList);
}

function* addAdmin(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiAdmin, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_ADMIN_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_ADMIN_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_ADMIN_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddAdmin() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_ADMIN_REQUEST, addAdmin);
}

function* editAdmin(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiAdmin}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_ADMIN_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_ADMIN_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_ADMIN_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditAdmin() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_ADMIN_REQUEST, editAdmin);
}

function* deleteAdmin(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiAdmin, { active: -1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_ADMIN_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_ADMIN_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_ADMIN_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteAdmin() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_ADMIN_REQUEST, deleteAdmin);
}

const saga = [
  watchGetAdminList(),
  watchAddAdmin(),
  watchEditAdmin(),
  watchDeleteAdmin(),
];

export default saga;
