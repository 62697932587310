import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/post";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

export const apiPost = "/v1/article";

function* getPostList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiPost, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_POST_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_POST_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_POST_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetPostList() {
  yield takeLatest(ACTION_TYPE.GET_POST_LIST_REQUEST, getPostList);
}

function* getPost(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiPost, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_POST_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_POST_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_POST_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetPost() {
  yield takeLatest(ACTION_TYPE.GET_POST_REQUEST, getPost);
}

function* addPost(action) {
  const { data, params, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiPost, data, { params }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_POST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_POST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_POST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddPost() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_POST_REQUEST, addPost);
}

function* editPost(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiPost}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_POST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_POST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_POST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditPost() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_POST_REQUEST, editPost);
}

function* deletePost(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiPost, null, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_POST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_POST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_POST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeletePost() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_POST_REQUEST, deletePost);
}

const saga = [
  watchGetPostList(),
  watchGetPost(),
  watchAddPost(),
  watchEditPost(),
  watchDeletePost(),
];

export default saga;
