export const ACTION_TYPE = {
  GET_PRODUCT_LIST_REQUEST: "product/GET_PRODUCT_LIST_REQUEST",
  GET_PRODUCT_LIST_SUCCESS: "product/GET_PRODUCT_LIST_SUCCESS",
  GET_PRODUCT_LIST_ERROR: "product/GET_PRODUCT_LIST_ERROR",
  GET_PRODUCT_REQUEST: "product/GET_PRODUCT_REQUEST",
  GET_PRODUCT_SUCCESS: "product/GET_PRODUCT_SUCCESS",
  GET_PRODUCT_ERROR: "product/GET_PRODUCT_ERROR",
  ADD_PRODUCT_REQUEST: "product/ADD_PRODUCT_REQUEST",
  ADD_PRODUCT_SUCCESS: "product/ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_ERROR: "product/ADD_PRODUCT_ERROR",
  EDIT_PRODUCT_REQUEST: "product/EDIT_PRODUCT_REQUEST",
  EDIT_PRODUCT_SUCCESS: "product/EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_ERROR: "product/EDIT_PRODUCT_ERROR",
  EDIT_PRODUCT_LIST_STATUS_REQUEST: "product/EDIT_PRODUCT_LIST_STATUS_REQUEST",
  EDIT_PRODUCT_LIST_STATUS_SUCCESS: "product/EDIT_PRODUCT_LIST_STATUS_SUCCESS",
  EDIT_PRODUCT_LIST_STATUS_ERROR: "product/EDIT_PRODUCT_LIST_STATUS_ERROR",
  DELETE_PRODUCT_LIST_REQUEST: "product/DELETE_PRODUCT_LIST_REQUEST",
  DELETE_PRODUCT_LIST_SUCCESS: "product/DELETE_PRODUCT_LIST_SUCCESS",
  DELETE_PRODUCT_LIST_ERROR: "product/DELETE_PRODUCT_LIST_ERROR",
  DELETE_PRODUCT_REQUEST: "product/DELETE_PRODUCT_REQUEST",
  DELETE_PRODUCT_SUCCESS: "product/DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_ERROR: "product/DELETE_PRODUCT_ERROR",
};

const initialState = {
  productList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
