import moment from "moment";
import { useCallback } from "react";

export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

export const validateStatus = (status) => {
  return 200 <= status && status < 300;
};

export const formatDate = (ISOString?: string) => {
  if (isEmpty(ISOString)) return "";
  return moment(ISOString).format("DD/MM/YYYY");
};

export const formatDateTime = (ISOString?: string) => {
  if (isEmpty(ISOString)) return "";
  return moment(ISOString).format("DD/MM/YYYY HH:mm");
};

export const convertDateInput = (ddMMyyyy: string) => {
  if (isEmpty(ddMMyyyy)) return "";
  return moment(ddMMyyyy, "DD/MM/YYYY").format("YYYY-MM-DD");
};

export const convertDateView = (dateInput: string) => {
  if (isEmpty(dateInput)) return "";
  return moment(dateInput, "YYYY-MM-DD").format("DD/MM/YYYY");
};

export const isEmpty = (string?: string) => {
  if (typeof string === "string") {
    return string.trim() === "";
  }
  return true;
};

export const convertTranslations = (item) => {
  if (Array.isArray(item?.translations)) {
    for (let i = 0; i < item.translations.length; i++) {
      let lang = item.translations[i];
      item[lang?.lang] = lang;
    }
  }
  return item;
};

export const parseDatetimeInput = (stringDate?: string) => {
  if (isEmpty(stringDate)) return "";
  return moment(stringDate).format("YYYY-MM-DDTHH:mm");
};

export const parseDateInput = (stringDate: string) => {
  if (isEmpty(stringDate)) return "";
  return moment(stringDate).format("YYYY-MM-DD");
};

export const parseDateISOString = (stringDate: string) => {
  if (isEmpty(stringDate)) return "";
  return moment(stringDate).toISOString();
};

export const currencyFormat = (price?: number) => {
  if (isEmpty(price?.toString())) return "";
  return `${price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}đ`;
};

export const isEmail = (email: string) => {
  const re = /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const mapImageUrl = (responseList) => {
  return responseList.map((response) => response?.data?.url);
};

//natural number
export const ERROR_NUMBER = "Vui lòng nhập số nguyên ko âm tối đa 14 kí tự";
export const isNumber = (number) => {
  const re = /^[0-9]{1,14}$/;
  return re.test(number?.toString());
};

export const isPhoneNumber = (number) => {
  const re = /^[0-9]{10}$/;
  return re.test(number?.toString());
};

export const isFloat = (number) => {
  const re = /^[-]?\d+(\.\d+)?$/;
  return re.test(number?.toString());
};

export const ERROR_PASSWORD =
  "Mật khẩu phải có ít nhất 8 ký tự, 1 ký tự đặc biệt, 1 chữ in hoa, 1 chữ thường, và 1 chữ số";
export const isPassword = (string) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  return re.test(string?.toString());
};

export const ERROR_SLUG_VI =
  "Slug phải từ 3-250 ký tự gồm chữ thường, số dấu '-' hoặc dấu '.'";
export const ERROR_SLUG_EN =
  "Slug must be 3-250 characters including lowercase letters, numbers with '-' or '.'";
export const isSlug = (string) => {
  if (string === undefined || string === null) return false;
  const re = /^[a-z-.0-9]{3,250}$/;
  return re.test(string);
};

export const ERROR_ACCOUNT =
  "Tên đăng nhập từ 3-32 kí tự gồm chữ thường hoặc số";
export const isAccount = (string) => {
  if (string === undefined || string === null) return false;
  const re = /^[a-z0-9]{3,32}$/;
  return re.test(string);
};
