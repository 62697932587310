
export const ACTION_TYPE = {
  GET_USER_LIST_REQUEST: "mobile-user/GET_USER_LIST_REQUEST",
  GET_USER_LIST_SUCCESS: "mobile-user/GET_USER_LIST_SUCCESS",
  GET_USER_LIST_ERROR: "mobile-user/GET_USER_LIST_ERROR",

  EDIT_USER_REQUEST: "mobile-user/EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "mobile-user/EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "mobile-user/EDIT_USER_ERROR",
  DELETE_USER_REQUEST: "mobile-user/DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "mobile-user/DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "mobile-user/DELETE_USER_ERROR",

  EDIT_STATE_USER_REQUEST: "mobile-user/EDIT_STATE_REQUEST",
  EDIT_STATE_USER_SUCCESS: "mobile-user/EDIT_STATE_USER_SUCCESS",
  EDIT_STATE_USER_ERROR: "mobile-user/EDIT_STATE_USER_ERROR",
};

const initialState = {
  userList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
