import { call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/transaction";
import { validateStatus } from "../util/validate";

const apiOrder = "/v1/order";

function* getTransactionList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiOrder, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_TRANSACTION_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_TRANSACTION_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_TRANSACTION_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetTransactionList() {
  yield takeLatest(ACTION_TYPE.GET_TRANSACTION_LIST_REQUEST, getTransactionList);
}

function* deleteTransaction(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiOrder, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_TRANSACTION_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_TRANSACTION_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_TRANSACTION_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchDeleteTransaction() {
  yield takeLatest(ACTION_TYPE.DELETE_TRANSACTION_REQUEST, deleteTransaction);
}

const saga = [watchGetTransactionList(), watchDeleteTransaction()];

export default saga;
