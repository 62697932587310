import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/mobile-user";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiUser = "/v1/user";

function* getUserList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiUser, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_USER_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_USER_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_USER_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetUserList() {
  yield takeLatest(ACTION_TYPE.GET_USER_LIST_REQUEST, getUserList);
}

function* editUser(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiUser}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_USER_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_USER_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_USER_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditUser() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.EDIT_USER_REQUEST, editUser);
}

function* deleteUser(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiUser}`, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_USER_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_USER_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_USER_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchDeleteUser() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_USER_REQUEST, deleteUser);
}

function* editStateUser(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiUser}/${id}/state`, data)
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_STATE_USER_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_STATE_USER_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_STATE_USER_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditStateUser() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_STATE_USER_REQUEST,
    editStateUser
  );
}

const saga = [
  watchGetUserList(),
  watchEditUser(),
  watchEditStateUser(),
  watchDeleteUser(),
];

export default saga;
