import { all, call, put, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/upload-file";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

export const apiUploadPhoto = "/v1/photo/upload";

function* uploadPhoto(action) {
  const { data, success, error } = action;
  try {
    let formData = new FormData();
    formData.append("photo", data);
    const response = yield call(() =>
      axios.post(apiUploadPhoto, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.UPLOAD_PHOTO_SUCCESS,
        payload: response?.data,
      });
      success && success(response);
    } else {
      yield put({
        type: ACTION_TYPE.UPLOAD_PHOTO_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.UPLOAD_PHOTO_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchUploadPhoto() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.UPLOAD_PHOTO_REQUEST, uploadPhoto);
}

function* uploadPhotoMultiple(action) {
  const { data, success, error } = action;
  try {
    const responseList = yield all(
      data.map((file) => {
        let formData = new FormData();
        formData.append("photo", file);
        return call(() =>
          axios.post(apiUploadPhoto, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        );
      })
    );
    yield put({
      type: ACTION_TYPE.UPLOAD_PHOTO_SUCCESS,
      payload: responseList,
    });
    success && success(responseList);
  } catch (e) {
    yield put({
      type: ACTION_TYPE.UPLOAD_PHOTO_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchUploadPhotoMultiple() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.UPLOAD_PHOTO_MUTIPLE_REQUEST,
    uploadPhotoMultiple
  );
}

const saga = [watchUploadPhoto(), watchUploadPhotoMultiple()];

export default saga;
