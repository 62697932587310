import { applyMiddleware, createStore } from "redux";

import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootReducer from "./reducer";
import rootSaga from "./saga";

export default function configureStore(initialState?) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  if (process.env.NODE_ENV === "development") {
    middleware.push(logger);
  }

  const store = createStore(
    rootReducer,
    initialState || {},
    applyMiddleware(...middleware)
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
