import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/email-sub";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiEmail = "/v1/email-sub";

function* getEmailList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiEmail, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_EMAIL_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_EMAIL_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_EMAIL_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetEmailList() {
  yield takeLatest(ACTION_TYPE.GET_EMAIL_LIST_REQUEST, getEmailList);
}

function* addEmail(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiEmail, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_EMAIL_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_EMAIL_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_EMAIL_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddEmail() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.ADD_EMAIL_REQUEST, addEmail);
}

function* deleteEmail(action) {
  const { data, params, success, error } = action;
  try {
    const response = yield call(() => axios.put(apiEmail, data, { params }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_EMAIL_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_EMAIL_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_EMAIL_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteEmail() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.DELETE_EMAIL_REQUEST, deleteEmail);
}

const saga = [watchGetEmailList(), watchAddEmail(), watchDeleteEmail()];

export default saga;
