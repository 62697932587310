import { call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/product";
import { validateStatus } from "../util/validate";

const apiProduct = "/v1/product";

function* getProductList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiProduct, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_PRODUCT_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetProductList() {
  yield takeLatest(ACTION_TYPE.GET_PRODUCT_LIST_REQUEST, getProductList);
}

function* getProduct(action) {
  const { success, error, id } = action;
  try {
    const response = yield call(() => axios.get(`${apiProduct}/${id}`));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_PRODUCT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetProduct() {
  yield takeLatest(ACTION_TYPE.GET_PRODUCT_REQUEST, getProduct);
}

function* addProduct(action) {
  const { data, params, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiProduct, data, { params }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_PRODUCT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_PRODUCT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_PRODUCT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddProduct() {
  yield takeLatest(ACTION_TYPE.ADD_PRODUCT_REQUEST, addProduct);
}

function* editProductListStatus(action) {
  const { data, params, success, error } = action;
  try {
    const response = yield call(() => axios.put(apiProduct, data, { params }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_LIST_STATUS_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_LIST_STATUS_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_PRODUCT_LIST_STATUS_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchEditProductListStatus() {
  yield takeLatest(
    ACTION_TYPE.EDIT_PRODUCT_LIST_STATUS_REQUEST,
    editProductListStatus
  );
}

function* editProduct(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() => axios.put(`${apiProduct}/${id}`, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_PRODUCT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchEditProduct() {
  yield takeLatest(ACTION_TYPE.EDIT_PRODUCT_REQUEST, editProduct);
}

function* deleteProductList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiProduct, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_PRODUCT_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_PRODUCT_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_PRODUCT_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteProductList() {
  yield takeLatest(ACTION_TYPE.DELETE_PRODUCT_LIST_REQUEST, deleteProductList);
}

function* deleteProduct(action) {
  const { id, success, error } = action;
  try {
    const response = yield call(() => axios.delete(`${apiProduct}/${id}`));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_PRODUCT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_PRODUCT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_PRODUCT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteProduct() {
  yield takeLatest(ACTION_TYPE.DELETE_PRODUCT_REQUEST, deleteProduct);
}

const saga = [
  watchGetProductList(),
  watchGetProduct(),
  watchAddProduct(),
  watchEditProductListStatus(),
  watchEditProduct(),
  watchDeleteProductList(),
  watchDeleteProduct(),
];

export default saga;
