export const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : undefined;
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_PAGE_SIZE)
  : 20;
export const MAX_FILE_SIZE_MB = process.env.REACT_APP_MAX_FILE_SIZE_MB
  ? parseInt(process.env.REACT_APP_MAX_FILE_SIZE_MB)
  : 5;
export const SAGA_THROTTLE = process.env.REACT_APP_SAGA_THROTTLE
  ? parseInt(process.env.REACT_APP_SAGA_THROTTLE)
  : 800;

export const NOTIFICATION_MAX_LENGTH = process.env
  .REACT_APP_NOTIFICATION_MAX_LENGTH
  ? parseInt(process.env.REACT_APP_NOTIFICATION_MAX_LENGTH)
  : 1000;

export const TABLE_FUNCTION_WIDTH = 43;

export enum Language {
  Vietnamese = "vi",
  English = "en",
}
