import "./style.css";

import { memo, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import Loader from "../../Components/loader";
import { RootState } from "../../reducer";

const Loading = memo(() => {
  const loading = useSelector((store: RootState) => store.loading.loading);
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        loading && document.getElementById("loading")?.classList?.add("active");
      }, 1000);
    }
  }, [loading]);

  if (!loading) return null;
  return createPortal(
    <div className="loading-overlay" id="loading">
      <Loader />
    </div>,
    document.body
  );
});
export default Loading;
