import { call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/contact-form";
import { validateStatus } from "../util/validate";

const apiContactForm = "/v1/contact-form";

function* getContactList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.get(apiContactForm, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_CONTACT_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_CONTACT_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_CONTACT_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetContactList() {
  yield takeLatest(ACTION_TYPE.GET_CONTACT_LIST_REQUEST, getContactList);
}

function* deleteContactList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiContactForm, { deleted: 1 }, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_CONTACT_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_CONTACT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_CONTACT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchDeleteContactList() {
  yield takeLatest(ACTION_TYPE.DELETE_CONTACT_REQUEST, deleteContactList);
}

const saga = [watchGetContactList(), watchDeleteContactList()];

export default saga;
