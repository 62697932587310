import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "../Components/scroll-to-top";
import Loading from "../Container/loading";
import ModalErrorMessage from "../Container/loading/modal-error-message";
import PrivateRouter from "./private-router";

const Login = lazy(() => import("../Container/loginn"));
const Home = lazy(() => import("../Container/home"));

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <ScrollToTop />
        <Loading />
        <ModalErrorMessage />
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <PrivateRouter path="/">
            <Home />
          </PrivateRouter>
        </Switch>
      </Router>
    </Suspense>
  );
};
export default Routes;
