export const ACTION_TYPE = {
  GET_FEEDBACK_LIST_REQUEST: "config-ui/GET_FEEDBACK_LIST_REQUEST",
  GET_FEEDBACK_LIST_SUCCESS: "config-ui/GET_FEEDBACK_LIST_SUCCESS",
  GET_FEEDBACK_LIST_ERROR: "config-ui/GET_FEEDBACK_LIST_ERROR",
};

const initialState = {
  feedbackList: [],
  hasNext: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        feedbackList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
