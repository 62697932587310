import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/notification";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

const apiNotification = "/v1/notification";

function* getNotificationList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.get(apiNotification, { params: data }));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_NOTIFICATION_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_NOTIFICATION_LIST_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_NOTIFICATION_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchGetNotificationList() {
  yield takeLatest(
    ACTION_TYPE.GET_NOTIFICATION_LIST_REQUEST,
    getNotificationList
  );
}

function* addNotification(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiNotification, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.ADD_NOTIFICATION_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.ADD_NOTIFICATION_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.ADD_NOTIFICATION_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchAddNotification() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.ADD_NOTIFICATION_REQUEST,
    addNotification
  );
}

function* editNotification(action) {
  const { data, id, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(`${apiNotification}/${id}`, data)
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_NOTIFICATION_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_NOTIFICATION_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_NOTIFICATION_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchEditNotification() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.EDIT_NOTIFICATION_REQUEST,
    editNotification
  );
}

function* deleteNotification(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiNotification, null, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.DELETE_NOTIFICATION_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.DELETE_NOTIFICATION_ERROR,
        payload: response?.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.DELETE_NOTIFICATION_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchDeleteNotification() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.DELETE_NOTIFICATION_REQUEST,
    deleteNotification
  );
}

const saga = [
  watchGetNotificationList(),
  watchAddNotification(),
  watchEditNotification(),
  watchDeleteNotification(),
];

export default saga;
