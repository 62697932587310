import Axios from "axios";
import { getToken } from "../reducer/auth";
import { apiLogin } from "../saga/auth";
import { API_BASE_URL } from "../util/constant";

const axios = Axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => {
    return 200 <= status && status < 500;
  },
});

axios.interceptors.request.use(
  (config) => {
    if (config.url !== apiLogin) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     console.log(error?.data);
//     return Promise.reject(error);
//   }
// );

export { axios };
