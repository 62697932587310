export const ACTION_TYPE = {
  GET_KEYWORD_LIST_REQUEST: "keyword/GET_KEYWORD_LIST_REQUEST",
  GET_KEYWORD_LIST_SUCCESS: "keyword/GET_KEYWORD_LIST_SUCCESS",
  GET_KEYWORD_LIST_ERROR: "keyword/GET_KEYWORD_LIST_ERROR",

  ADD_KEYWORD_REQUEST: "keyword/ADD_KEYWORD_REQUEST",
  ADD_KEYWORD_SUCCESS: "keyword/ADD_KEYWORD_SUCCESS",
  ADD_KEYWORD_ERROR: "keyword/ADD_KEYWORD_ERROR",
  EDIT_KEYWORD_REQUEST: "keyword/EDIT_KEYWORD_REQUEST",
  EDIT_KEYWORD_SUCCESS: "keyword/EDIT_KEYWORD_SUCCESS",
  EDIT_KEYWORD_ERROR: "keyword/EDIT_KEYWORD_ERROR",
  DELETE_KEYWORD_REQUEST: "keyword/DELETE_KEYWORD_REQUEST",
  DELETE_KEYWORD_SUCCESS: "keyword/DELETE_KEYWORD_SUCCESS",
  DELETE_KEYWORD_ERROR: "keyword/DELETE_KEYWORD_ERROR",
};

const initialState = {
  keywordList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_KEYWORD_LIST_SUCCESS:
      return {
        ...state,
        keywordList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
