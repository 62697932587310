export const ACTION_TYPE = {
  GET_CONTACT_LIST_REQUEST: "contact-form/GET_CONTACT_LIST_REQUEST",
  GET_CONTACT_LIST_SUCCESS: "contact-form/GET_CONTACT_LIST_SUCCESS",
  GET_CONTACT_LIST_ERROR: "contact-form/GET_CONTACT_LIST_ERROR",

  DELETE_CONTACT_REQUEST: "contact-form/DELETE_CONTACT_REQUEST",
  DELETE_CONTACT_SUCCESS: "contact-form/DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_ERROR: "contact-form/DELETE_CONTACT_ERROR",
};

const initialState = {
  contactList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        contactList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
