import { convertTranslations } from "../util/validate";

export const ACTION_TYPE = {
  GET_EVENT_LIST_REQUEST: "event/GET_EVENT_LIST_REQUEST",
  GET_EVENT_LIST_SUCCESS: "event/GET_EVENT_LIST_SUCCESS",
  GET_EVENT_LIST_ERROR: "event/GET_EVENT_LIST_ERROR",

  ADD_EVENT_REQUEST: "event/ADD_EVENT_REQUEST",
  ADD_EVENT_SUCCESS: "event/ADD_EVENT_SUCCESS",
  ADD_EVENT_ERROR: "event/ADD_EVENT_ERROR",
  EDIT_EVENT_REQUEST: "event/EDIT_EVENT_REQUEST",
  EDIT_EVENT_SUCCESS: "event/EDIT_EVENT_SUCCESS",
  EDIT_EVENT_ERROR: "event/EDIT_EVENT_ERROR",
  DELETE_EVENT_REQUEST: "event/DELETE_EVENT_REQUEST",
  DELETE_EVENT_SUCCESS: "event/DELETE_EVENT_SUCCESS",
  DELETE_EVENT_ERROR: "event/DELETE_EVENT_ERROR",
};

const initialState = {
  eventList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_EVENT_LIST_SUCCESS:
      let eventList = action?.payload?.results;
      if (Array.isArray(eventList)) {
        eventList.forEach((item) => convertTranslations(item));
      }
      return {
        ...state,
        eventList,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
