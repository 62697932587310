import { call, put, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/dashboard";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

export const apiDashboard = "/v1/setup/dash-board";

function* getDashboardReport(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(apiDashboard));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_DASHBOARD_REPORT_SUCCESS,
        payload: response?.data,
      });
      success && success(response);
    } else {
      yield put({
        type: ACTION_TYPE.GET_DASHBOARD_REPORT_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_DASHBOARD_REPORT_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetDashboardReport() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.GET_DASHBOARD_REPORT_REQUEST,
    getDashboardReport
  );
}

const saga = [watchGetDashboardReport()];

export default saga;
