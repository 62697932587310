export const ACTION_TYPE = {
  LOGIN_REQUEST: "auth/LOGIN_REQUEST",
  LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
  LOGIN_ERROR: "auth/LOGIN_ERROR",

  GET_PROFILE_REQUEST: "auth/GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "auth/GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "auth/GET_PROFILE_ERROR",

  CHANGE_PASSWORD_REQUEST: "auth/CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "auth/CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR: "auth/CHANGE_PASSWORD_ERROR",
};

const initialState = {
  profile: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.LOGIN_SUCCESS:
      setToken(action?.payload?.access_token);
      return {
        ...state,
      };
    case ACTION_TYPE.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action?.payload,
      };
    default:
      return state;
  }
};

const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token") || "";
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export default reducer;
