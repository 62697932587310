import { call, put, takeLatest } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/product-review";
import { validateStatus } from "../util/validate";

const apiProductReivew = "/v1/product-review";

function* getProductReviewList(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() =>
      axios.get(apiProductReivew, { params: data })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_REVIEW_LIST_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_PRODUCT_REVIEW_LIST_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_PRODUCT_REVIEW_LIST_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* editProductListStatus(action) {
  const { data, params, success, error } = action;
  try {
    const response = yield call(() =>
      axios.put(apiProductReivew, data, { params })
    );
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_REVIEW_LIST_STATUS_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.EDIT_PRODUCT_REVIEW_LIST_STATUS_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.EDIT_PRODUCT_REVIEW_LIST_STATUS_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}
function* watchEditProductListStatus() {
  yield takeLatest(
    ACTION_TYPE.EDIT_PRODUCT_REVIEW_LIST_STATUS_REQUEST,
    editProductListStatus
  );
}
function* watchGetProductReviewList() {
  yield takeLatest(
    ACTION_TYPE.GET_PRODUCT_REVIEW_LIST_REQUEST,
    getProductReviewList
  );
}
const saga = [watchGetProductReviewList(), watchEditProductListStatus()];

export default saga;
