export const ACTION_TYPE = {
  GET_CONFIG_UI_HOME_REQUEST: "config-ui/GET_CONFIG_UI_HOME_REQUEST",
  GET_CONFIG_UI_HOME_SUCCESS: "config-ui/GET_CONFIG_UI_HOME_SUCCESS",
  GET_CONFIG_UI_HOME_ERROR: "config-ui/GET_CONFIG_UI_HOME_ERROR",
  GET_CONFIG_UI_MENU_REQUEST: "config-ui/GET_CONFIG_UI_MENU_REQUEST",
  GET_CONFIG_UI_MENU_SUCCESS: "config-ui/GET_CONFIG_UI_MENU_SUCCESS",
  GET_CONFIG_UI_MENU_ERROR: "config-ui/GET_CONFIG_UI_MENU_ERROR",
  UPDATE_CONFIG_UI_HOME_REQUEST: "config-ui/UPDATE_CONFIG_UI_HOME_REQUEST",
  UPDATE_CONFIG_UI_HOME_SUCCESS: "config-ui/UPDATE_CONFIG_UI_HOME_SUCCESS",
  UPDATE_CONFIG_UI_HOME_ERROR: "config-ui/UPDATE_CONFIG_UI_HOME_ERROR",
  UPDATE_CONFIG_UI_MENU_REQUEST: "config-ui/UPDATE_CONFIG_UI_MENU_REQUEST",
  UPDATE_CONFIG_UI_MENU_SUCCESS: "config-ui/UPDATE_CONFIG_UI_MENU_SUCCESS",
  UPDATE_CONFIG_UI_MENU_ERROR: "config-ui/UPDATE_CONFIG_UI_MENU_ERROR",

  GET_CONFIG_KEY_REQUEST: "config-ui/GET_CONFIG_KEY_REQUEST",
  GET_CONFIG_KEY_SUCCESS: "config-ui/GET_CONFIG_KEY_SUCCESS",
  GET_CONFIG_KEY_ERROR: "config-ui/GET_CONFIG_KEY_ERROR",
  EDIT_CONFIG_KEY_REQUEST: "config-ui/EDIT_CONFIG_KEY_REQUEST",
  EDIT_CONFIG_KEY_SUCCESS: "config-ui/EDIT_CONFIG_KEY_SUCCESS",
  EDIT_CONFIG_KEY_ERROR: "config-ui/EDIT_CONFIG_KEY_ERROR",
};

const initialState = {
  menuConfig: [],
  homeConfig: [],
  configKey: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_CONFIG_UI_HOME_SUCCESS:
      return {
        ...state,
        homeConfig: action?.payload.data,
      };
    case ACTION_TYPE.GET_CONFIG_UI_MENU_SUCCESS:
      return {
        ...state,
        menuConfig: action?.payload.data,
      };
    case ACTION_TYPE.GET_CONFIG_KEY_SUCCESS:
      return {
        ...state,
        configKey: action?.payload,
      };
    default:
      return state;
  }
};

export default reducer;
