export const ACTION_TYPE = {
  GET_CITY_LIST_REQUEST: "project-map/GET_CITY_LIST_REQUEST",
  GET_CITY_LIST_SUCCESS: "project-map/GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_ERROR: "project-map/GET_CITY_LIST_ERROR",
  GET_DISTRICT_LIST_REQUEST: "project-map/GET_DISTRICT_LIST_REQUEST",
  GET_DISTRICT_LIST_SUCCESS: "project-map/GET_DISTRICT_LIST_SUCCESS",
  GET_DISTRICT_LIST_ERROR: "project-map/GET_DISTRICT_LIST_ERROR",
  GET_WARD_LIST_REQUEST: "project-map/GET_WARD_LIST_REQUEST",
  GET_WARD_LIST_SUCCESS: "project-map/GET_WARD_LIST_SUCCESS",
  GET_WARD_LIST_ERROR: "project-map/GET_WARD_LIST_ERROR",

  ADD_CITY_REQUEST: "project-map/ADD_CITY_REQUEST",
  ADD_CITY_SUCCESS: "project-map/ADD_CITY_SUCCESS",
  ADD_CITY_ERROR: "project-map/ADD_CITY_ERROR",
  ADD_DISTRICT_REQUEST: "project-map/ADD_DISTRICT_REQUEST",
  ADD_DISTRICT_SUCCESS: "project-map/ADD_DISTRICT_SUCCESS",
  ADD_DISTRICT_ERROR: "project-map/ADD_DISTRICT_ERROR",
  ADD_WARD_REQUEST: "project-map/ADD_WARD_REQUEST",
  ADD_WARD_SUCCESS: "project-map/ADD_WARD_SUCCESS",
  ADD_WARD_ERROR: "project-map/ADD_WARD_ERROR",

  EDIT_CITY_REQUEST: "project-map/EDIT_CITY_REQUEST",
  EDIT_CITY_SUCCESS: "project-map/EDIT_CITY_SUCCESS",
  EDIT_CITY_ERROR: "project-map/EDIT_CITY_ERROR",
  EDIT_DISTRICT_REQUEST: "project-map/EDIT_DISTRICT_REQUEST",
  EDIT_DISTRICT_SUCCESS: "project-map/EDIT_DISTRICT_SUCCESS",
  EDIT_DISTRICT_ERROR: "project-map/EDIT_DISTRICT_ERROR",
  EDIT_WARD_REQUEST: "project-map/EDIT_WARD_REQUEST",
  EDIT_WARD_SUCCESS: "project-map/EDIT_WARD_SUCCESS",
  EDIT_WARD_ERROR: "project-map/EDIT_WARD_ERROR",

  DELETE_CITY_REQUEST: "project-map/DELETE_CITY_REQUEST",
  DELETE_CITY_SUCCESS: "project-map/DELETE_CITY_SUCCESS",
  DELETE_CITY_ERROR: "project-map/DELETE_CITY_ERROR",
  DELETE_DISTRICT_REQUEST: "project-map/DELETE_DISTRICT_REQUEST",
  DELETE_DISTRICT_SUCCESS: "project-map/DELETE_DISTRICT_SUCCESS",
  DELETE_DISTRICT_ERROR: "project-map/DELETE_DISTRICT_ERROR",
  DELETE_WARD_REQUEST: "project-map/DELETE_WARD_REQUEST",
  DELETE_WARD_SUCCESS: "project-map/DELETE_WARD_SUCCESS",
  DELETE_WARD_ERROR: "project-map/DELETE_WARD_ERROR",
};

const initialState = {
  cityList: [],
  cityListHasNext: false,
  districtList: [],
  districtListHasNext: false,
  wardList: [],
  wardListHasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: action?.payload?.results,
        cityListHasNext: action?.payload?.hasNext,
      };
    case ACTION_TYPE.GET_DISTRICT_LIST_SUCCESS:
      return {
        ...state,
        districtList: action?.payload?.results,
        districtListHasNext: action?.payload?.hasNext,
      };
    case ACTION_TYPE.GET_WARD_LIST_SUCCESS:
      return {
        ...state,
        wardList: action?.payload?.results,
        wardListHasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
