import DateFnsUtils from "@date-io/date-fns";
import { viVN } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import viLocale from "date-fns/locale/vi";
import "react-datepicker/dist/react-datepicker.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import "./App.css";
import Routes from "./route";
import configureStore from "./store";

const THEME = createMuiTheme(
  {
    typography: {
      fontFamily: `"Montserrat"`,
      fontSize: 14,
    },
  },
  viVN
);

const store = configureStore();

const App = () => {
  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={viLocale}>
          <DndProvider backend={HTML5Backend}>
            <Routes />
          </DndProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
