import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { axios } from "../axios";
import { ACTION_TYPE } from "../reducer/auth";
import { SAGA_THROTTLE } from "../util/constant";
import { validateStatus } from "../util/validate";

export const apiLogin = "/v1/auth/web/login";
const apiProfile = "/v1/auth/web/profile";
const apiChangePassword = "/v1/my-account/web/profile";

function* login(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiLogin, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.LOGIN_SUCCESS,
        payload: response?.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.LOGIN_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.LOGIN_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchLogin() {
  yield throttle(SAGA_THROTTLE, ACTION_TYPE.LOGIN_REQUEST, login);
}

function* getProfile(action) {
  const { success, error } = action;
  try {
    const response = yield call(() => axios.get(apiProfile));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.GET_PROFILE_SUCCESS,
        payload: response.data,
      });
      success && success();
    } else {
      yield put({
        type: ACTION_TYPE.GET_PROFILE_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.GET_PROFILE_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchGetProfile() {
  yield takeLatest(ACTION_TYPE.GET_PROFILE_REQUEST, getProfile);
}

function* changePassword(action) {
  const { data, success, error } = action;
  try {
    const response = yield call(() => axios.post(apiChangePassword, data));
    if (validateStatus(response?.status)) {
      yield put({
        type: ACTION_TYPE.CHANGE_PASSWORD_SUCCESS,
        payload: response.data,
      });
      success && success(response);
    } else {
      yield put({
        type: ACTION_TYPE.CHANGE_PASSWORD_ERROR,
        payload: response.data,
      });
      error && error(response);
    }
  } catch (e) {
    yield put({
      type: ACTION_TYPE.CHANGE_PASSWORD_ERROR,
      payload: e?.message,
    });
    error && error(e?.message);
  }
}

function* watchChangePassword() {
  yield throttle(
    SAGA_THROTTLE,
    ACTION_TYPE.CHANGE_PASSWORD_REQUEST,
    changePassword
  );
}

const saga = [watchLogin(), watchGetProfile(), watchChangePassword()];

export default saga;
