export const ACTION_TYPE = {
  GET_DASHBOARD_REPORT_REQUEST: "dashboard/GET_DASHBOARD_REPORT_REQUEST",
  GET_DASHBOARD_REPORT_SUCCESS: "dashboard/GET_DASHBOARD_REPORT_SUCCESS",
  GET_DASHBOARD_REPORT_ERROR: "dashboard/GET_DASHBOARD_REPORT_ERROR",
};

const initialState = {
  dashboardReport: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        dashboardReport: action?.payload,
      };
    default:
      return state;
  }
};

export default reducer;
