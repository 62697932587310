export const ACTION_TYPE = {
  GET_NOTIFICATION_LIST_REQUEST: "notification/GET_NOTIFICATION_LIST_REQUEST",
  GET_NOTIFICATION_LIST_SUCCESS: "notification/GET_NOTIFICATION_LIST_SUCCESS",
  GET_NOTIFICATION_LIST_ERROR: "notification/GET_NOTIFICATION_LIST_ERROR",

  ADD_NOTIFICATION_REQUEST: "notification/ADD_NOTIFICATION_REQUEST",
  ADD_NOTIFICATION_SUCCESS: "notification/ADD_NOTIFICATION_SUCCESS",
  ADD_NOTIFICATION_ERROR: "notification/ADD_NOTIFICATION_ERROR",
  EDIT_NOTIFICATION_REQUEST: "notification/EDIT_NOTIFICATION_REQUEST",
  EDIT_NOTIFICATION_SUCCESS: "notification/EDIT_NOTIFICATION_SUCCESS",
  EDIT_NOTIFICATION_ERROR: "notification/EDIT_NOTIFICATION_ERROR",
  DELETE_NOTIFICATION_REQUEST: "notification/DELETE_NOTIFICATION_REQUEST",
  DELETE_NOTIFICATION_SUCCESS: "notification/DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_ERROR: "notification/DELETE_NOTIFICATION_ERROR",
};

const initialState = {
  notificationList: [],
  hasNext: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: action?.payload?.results,
        hasNext: action?.payload?.hasNext,
      };
    default:
      return state;
  }
};

export default reducer;
