export const ACTION_TYPE = {
  GET_NEWS_LIST_REQUEST: "news/GET_NEWS_LIST_REQUEST",
  GET_NEWS_LIST_SUCCESS: "news/GET_NEWS_LIST_SUCCESS",
  GET_NEWS_LIST_ERROR: "news/GET_NEWS_LIST_ERROR",

  EDIT_NEWS_REQUEST: "news/EDIT_NEWS_REQUEST",
  EDIT_NEWS_SUCCESS: "news/EDIT_NEWS_SUCCESS",
  EDIT_NEWS_ERROR: "news/EDIT_NEWS_ERROR",
};

const initialState = {
  newsList: [{
    _id: "1",
    name: "Giới thiệu",
    isPublish: 1
  }],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        newsList: action?.payload?.results,
      };
    default:
      return state;
  }
};

export default reducer;
